import Hero from "@/components/Hero";
import SeoHead from "@/components/SeoHead";

export default function ErrorPage() {
  const page = {
    title: {
      path: "404.hero.title",
      placeholder: "404 - Page Not Found",
    },
    background: {
      path: "404.hero.image"
    }
  }
  return (
    <>
      <SeoHead title="Page Not Found" canonical="/404" />
      <Hero background={page.background} title={page.title} />
    </>
  );
}
