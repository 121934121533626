import React from "react";
import PropTypes from "prop-types";
import { DynamicText } from "@/ui";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { useEffect } from "react";
/**
 *
 * @param className
 * @param title
 * @param tag
 * @returns {React.DetailedReactHTMLElement<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>|JSX.Element|null}
 * @constructor
 */
export default function Title({ className, title, tag, highlight, variant }) {
  
  if (!title) {
    return null;
  }

  if (typeof title === "string") {
    if (highlight) {
      title = title.replace(highlight, `<span class='highlight-span ${variant}'>${highlight}</span>`);
    }

    return React.createElement(tag, {
      className: clsx(classes.title, className, variant),
      dangerouslySetInnerHTML: { __html: title },
    });
  }

  return (
    <DynamicText className={clsx(classes.title, className)} path={title.path} tag={tag}>
      {title.placeholder}
    </DynamicText>
  );
}

Title.defaultProps = {
  className: "",
  tag: "h2",
  title: null,
};

Title.propTypes = {
  className: PropTypes.string,
  tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "div", "span"]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
