import PropTypes from "prop-types";
import clsx from "classnames";
import classes from "./styles.module.scss";
import Title from "@/ui/Title";
import Text from "../Shared/Text";
import Image from "@/ui/Image";

export default function Hero({ className, path, title, subtitle, background, alt, video, image }) {

  

  return (
    <div className={clsx(className, classes.hero)}>
      {
        video ? <video autoPlay loop playsInline muted className={classes.bg_image} src={video} /> :
        image ? <img src={image} className={`${classes.bg_image}`} alt={alt} /> :
        <Image img={background} size="1440x500" className={`${classes.bg_image}`} alt={alt} />
      }
      {subtitle?.path && <Title title={subtitle} tag="div" className={`h5 ${classes.subtitle}`} />}
      {title?.path && <Title title={title} tag="h1" className={classes.title} />}
      {path && <Text path={path} />}
    </div>
  );
}

Hero.defaultProps = {
  className: "",
};

Hero.propTypes = {
  className: PropTypes.string,
};
