import React from "react";
import Link from 'next/link';
import SourceFlowText from "@sourceflow-uk/sourceflowtext";
import { getGlobal } from "@/functions/getGlobal";

import styles  from './styles.module.scss';

export default function Text({path}) {

    const global = getGlobal();

    return (
        <div className={styles.cont}>
            <SourceFlowText global={global} path={path} type="html" />
        </div>
    )
}